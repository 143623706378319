import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout-minhelt/layout"
import Seo from "../components/Seo"
import getMetatags from "../util/getMetatags"
import ReadMoreArrow from "../images/read-more.svg"
import DividerLigjtBlue from "../images/divider-ligt-blue.svg"
import Image from "../components/Image"
import { HeroMinhelet } from "../components/hero"

const IndexPage = ({ data, ...props }) => {

  const { node } = data

  const seo = getMetatags(data.node.metatag/* , data.node.relationships.OGImage?.localFile?.publicURL */)

  //const { title0 } = node
  const { hero, items } = node.relationships

  return (
    <Layout>
      <Seo {...seo} />
      <HeroMinhelet
        className="mt-[90px] lg:mt-[126px]  h-[147px] lg:h-[350px] "
        {...hero}
        url={hero?.relationships?.image?.localFile?.publicURL}
      />

      <section className="  py-12 lg:py-32 ">
        <div className="container px-1 lg:px-6 spacey-12 lg:space-y-24 ">

          {/* <h2 className="text-blue text-[34px] text-center font-bold">{title0}</h2> */}

          <div className="flex flex-col lg:flex-row lg:space-x-8 lg:space-x-reverse">
            <div className="lg:w-2/3  space-y-12">

              <div className="space-y-12">
                {items.map((item, index) => (
                  <Item1 {...item} key={index} />
                ))}
              </div>
            </div>
            <div className="lg:w-1/3  lg:space-y-12 lg:bg-[url(/backgrounds/pattern6.svg)] bg-no-repeat lg:min-h-[680px]">

            </div>
          </div>
        </div>
      </section>

      { /* width !== 0 && */ <React.Suspense fallback="loading...">
      </React.Suspense>}


    </Layout >
  )
}

export default IndexPage


const Item1 = ({ title, url, html, image, relationships, ...props }) => {

  const date = new Date(props.date_full)
  const now = new Date();
  const dateIsInThePast = date.getTime() <= now.getTime();

  return (
    <div className="flex flex-col lg:flex-row lg:space-x-12 lg:space-x-reverse items-top ">
      <div className="min-w-[80px] lg:text-center px-2">
        <div className="mx-auto space-y-3 font-bold text-lg">
          <div className="">דדליין</div>
          <img src={DividerLigjtBlue} alt="" className="lg:text-center lg:mx-auto"  />
          <div className={dateIsInThePast ? 'line-through decoration-2' : ''}>
            {props.date}
            <span class="sr-only">התאריך עבר</span>
          </div>
        </div>
      </div>
      <div className="lg:max-w-[341px]">
        <div className=" relative m-[10px]  ">
          <div className="w-[80px] h-[80px] bg-blue absolute top-[-10px] right-[-10px] -z-10"></div>
          <div className="w-[80px] h-[80px] bg-yellow absolute bottom-[-10px] left-[-10px] -z-10"></div>
          <div className=" ">
            <Image image={relationships.image} alt="" className="lg:inline" />
          </div>
        </div>
      </div>
      <div className="lg:max-w-[340px] text-black">
        <h2 className="text-2xl font-bold text-blue">{title}</h2>
        <div className="text-lg py-4" dangerouslySetInnerHTML={{ __html: html.processed }}></div>
        {!dateIsInThePast && <a href={url} target="__blank" className="text-yellow2 text-base"
        aria-label={"המשך קריאה " + title}
        >
          {"המשך קריאה"} <img src={ReadMoreArrow} alt="" className="inline" />
        </a>}
      </div>

    </div>
  )
}


export const query = graphql`
  query newsMichraizm($id: String!) {
    node: nodeTenders(id: {eq: $id }) {
      title


      metatag {
        attributes {
          content
          name
        }
        tag
      }
      relationships {
        hero: field_hero {
          ...Hero
        }
        items: field_paragraph_repeater{
          ...Tender
        }
      }
    }

  }
`
